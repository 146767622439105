import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  tags,
  tagsForm,
  expenses,
  expensesForm,
  counts,
  countsForm,
  budgets,
  budgetsForm,
  gass,
  gassForm,
  travels,
  travelsForm,
} from "./Reducers";

export default combineReducers({
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  tags,
  tagsForm,
  expenses,
  expensesForm,
  counts,
  countsForm,
  budgets,
  budgetsForm,
  gass,
  gassForm,
  currentTheme,
  travels,
  travelsForm,
});
