import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_TAG,
  DELETE_TAG,
  TAG_FORM_TOOGLE_LOADING,
  SET_TAG_LIST,
  SHOW_NOTIFICATION,
  UPDATE_TAG,
} from "../constants";
import { formatTags } from "./settingsActionsUtils";

/* TAG LIST */
export const fetchTags = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.tags.list)
      .then((response) => {
        const tags = formatTags(response.data);
        dispatch({ type: SET_TAG_LIST, payload: keyBy(tags, "_id") });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// TAGS
export const createTag = (newTag) => {
  return async (dispatch) => {
    dispatch({ type: TAG_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.tags.create, newTag)
      .then((response) => {
        const tag = formatTags(response.data);
        dispatch({ type: ADD_NEW_TAG, payload: tag });
        dispatch({ type: TAG_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Tag creada con éxito.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: TAG_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateTag = (updatedTag) => {
  return async (dispatch) => {
    dispatch({ type: TAG_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.tags.edit}${updatedTag._id}`, updatedTag)
      .then((response) => {
        const tag = formatTags(response.data);
        dispatch({ type: UPDATE_TAG, payload: tag });
        dispatch({ type: TAG_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Tag actualizada.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: TAG_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteTags = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: TAG_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.tags.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_TAG, payload: Ids });
        dispatch({ type: TAG_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Tags eliminadas. ",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: TAG_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
