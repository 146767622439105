import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
import { ROLES_RAW } from "../../constants/permissions";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
  (state) => state.authorization,
  (authorization) => authorization
);
export const getCurrentRoute = createSelector(
  (state) => state.currentRoute,
  (currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
  getCurrentRoute,
  (route) => route.title
);
export const getSideMenuState = createSelector(
  (state) => state.app,
  (app) => app.isCollapsedSideMenu
);
export const getCurrentTheme = createSelector(
  (state) => state.currentTheme,
  (currentTheme) => currentTheme
);

/* PROFILE */
export const getUserProfile = createSelector(
  (state) => state.profile,
  (profile) => profile
);
export const currentUserId = createSelector(
  (state) => state.profile,
  (profile) => profile && profile.id
);
export const getCurrentUserRole = createSelector(getUserProfile, (profile) =>
  !profile ? null : (ROLES_RAW.includes(profile.role) ? profile.role : null)
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

/* USERS */
export const getUsers = createSelector(
  (state) => state.users,
  (users) => users
);
export const getUsersForm = createSelector(
  (state) => state.usersForm,
  (form) => form
);

/* ALERT */
export const getAlerts = createSelector(
  (state) => state.alerts,
  (alerts) => alerts
);
export const getAlertsForm = createSelector(
  (state) => state.alertsForm,
  (form) => form
);

/* TAG */
export const getTags = createSelector(
  (state) => state.tags,
  (tags) => tags
);
export const getTagsForm = createSelector(
  (state) => state.tagsForm,
  (form) => form
);

/* TRAVEL */
export const getTravels = createSelector(
  (state) => state.travels,
  (travels) => travels
);
export const getTravelsForm = createSelector(
  (state) => state.travelsForm,
  (form) => form
);

/* BUDGET */
export const getBudgets = createSelector(
  (state) => state.budgets,
  (budgets) => budgets
);
export const getBudgetsForm = createSelector(
  (state) => state.budgetsForm,
  (form) => form
);

/* COUNT */
export const getCounts = createSelector(
  (state) => state.counts,
  (counts) => counts
);
export const getCountsForm = createSelector(
  (state) => state.countsForm,
  (form) => form
);

/* EXPENSE */
export const getExpenses = createSelector(
  (state) => state.expenses,
  (expenses) => expenses
);
export const getExpensesForm = createSelector(
  (state) => state.expensesForm,
  (form) => form
);

/* GASS */
export const getGass = createSelector(
  (state) => state.gass,
  (gass) => gass
);
export const getGassForm = createSelector(
  (state) => state.gassForm,
  (form) => form
);
