import { isArray, isObject } from "lodash";
import moment from "moment";

export const formatUsers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name} ${data.surname}`,
      value: data._id
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name} ${data.surname}`
    };
  }
};

export const formatAlerts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
    };
  }
};

export const formatExpenses = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
      date: data.date && moment(data.date),
    };
  }
};

export const formatBudgets = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
      date: data.date && moment(data.date),
    };
  }
};


export const formatCounts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
    };
  }
};

export const formatGass = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
      date: data.date && moment(data.date),
    };
  }
};

export const formatTags = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
    };
  }
};

export const formatTravels = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    };
  }
};