export const API = {
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
  tags: {
    create: `/tag`,
    edit: `/tag/`,
    delete: `/tag/`,
    list: `/tag`,
  },
  travels: {
    create: `/travel`,
    edit: `/travel/`,
    delete: `/travel/`,
    list: `/travel`,
  },
  budgets: {
    create: `/budget`,
    edit: `/budget/`,
    delete: `/budget/`,
    list: `/budget`,
  },
  counts: {
    create: `/count`,
    edit: `/count/`,
    delete: `/count/`,
    list: `/count`,
  },
  expenses: {
    create: `/expense`,
    edit: `/expense/`,
    delete: `/expense/`,
    list: `/expense`,
  },
  gass: {
    create: `/gass`,
    edit: `/gass/`,
    delete: `/gass/`,
    list: `/gass`,
  },
};
