import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_TRAVEL,
  DELETE_TRAVEL,
  TRAVEL_FORM_TOOGLE_LOADING,
  SET_TRAVEL_LIST,
  SHOW_NOTIFICATION,
  UPDATE_TRAVEL,
} from "../constants";
import { formatTravels } from "./settingsActionsUtils";

/* TRAVEL LIST */
export const fetchTravels = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.travels.list)
      .then((response) => {
        const travels = formatTravels(response.data);
        dispatch({ type: SET_TRAVEL_LIST, payload: keyBy(travels, "_id") });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// TRAVELS
export const createTravel = (newTravel) => {
  return async (dispatch) => {
    dispatch({ type: TRAVEL_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.travels.create, newTravel)
      .then((response) => {
        const travel = formatTravels(response.data);
        dispatch({ type: ADD_NEW_TRAVEL, payload: travel });
        dispatch({ type: TRAVEL_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Viaje creado con éxito.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: TRAVEL_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateTravel = (updatedTravel) => {
  return async (dispatch) => {
    dispatch({ type: TRAVEL_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.travels.edit}${updatedTravel._id}`, updatedTravel)
      .then((response) => {
        const travel = formatTravels(response.data);
        dispatch({ type: UPDATE_TRAVEL, payload: travel });
        dispatch({ type: TRAVEL_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Viaje actualizado.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: TRAVEL_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteTravels = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: TRAVEL_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.travels.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_TRAVEL, payload: Ids });
        dispatch({ type: TRAVEL_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Viaje eliminados. ",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: TRAVEL_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
