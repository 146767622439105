import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_BUDGET,
  DELETE_BUDGET,
  BUDGET_FORM_TOOGLE_LOADING,
  SET_BUDGET_LIST,
  SHOW_NOTIFICATION,
  UPDATE_BUDGET,
} from "../constants";
import { formatBudgets } from "./settingsActionsUtils";

/* BUDGET LIST */
export const fetchBudgets = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.budgets.list)
      .then((response) => {
        const budgets = formatBudgets(response.data);
        dispatch({ type: SET_BUDGET_LIST, payload: keyBy(budgets, "_id") });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// BUDGETS
export const createBudget = (newBudget) => {
  return async (dispatch) => {
    dispatch({ type: BUDGET_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.budgets.create, newBudget)
      .then((response) => {
        const budget = formatBudgets(response.data);
        dispatch({ type: ADD_NEW_BUDGET, payload: budget });
        dispatch({ type: BUDGET_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Presupuesto creado con éxito.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: BUDGET_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateBudget = (updatedBudget) => {
  return async (dispatch) => {
    dispatch({ type: BUDGET_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.budgets.edit}${updatedBudget._id}`, updatedBudget)
      .then((response) => {
        const budget = formatBudgets(response.data);
        dispatch({ type: UPDATE_BUDGET, payload: budget });
        dispatch({ type: BUDGET_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Presupuesto actualizado.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: BUDGET_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteBudgets = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: BUDGET_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.budgets.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_BUDGET, payload: Ids });
        dispatch({ type: BUDGET_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Presupuestos eliminados. ",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: BUDGET_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
